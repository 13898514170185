import styled, { useTheme } from 'styled-components';
import { IconButton, Menu } from '@miri-unicorn/miricanvas-ds';
import { useTranslation } from '@configs/i18next';
import { ZendeskLinkType, getZendeskLink } from '@constants/zendesk_link';
import { CustomerGuideItem } from '@ui/common/help_center/HelpCenterPopup';

type ChildGuideListProps = {
  onClose: () => void;
  backToMainGuideList: () => void;
  childDepthTitle: string;
  childDepthGuideList: CustomerGuideItem[];
  clickZendeskOpen: () => void;
};
export const ChildGuideList = ({
  onClose,
  backToMainGuideList,
  childDepthTitle,
  childDepthGuideList,
  clickZendeskOpen,
}: ChildGuideListProps) => {
  const { translate } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Header>
        <Title>
          <IconButton
            size={'S'}
            type={'ghost'}
            color={'neutralLight'}
            shape={'square'}
            iconName={'chevron_left'}
            onClick={backToMainGuideList}
          />
          <Text>{translate(childDepthTitle)}</Text>
        </Title>
        <IconButton
          size={'S'}
          type={'ghost'}
          shape={'square'}
          iconName={'close'}
          color={'neutralLight'}
          onClick={onClose}
        />
      </Header>
      <Content>
        {childDepthGuideList?.map((item, idx) => {
          return (
            <Menu
              key={idx}
              type={'menu'}
              size={'M'}
              selected={false}
              textOverflow={'newLine'}
              iconColor={theme.token.c_menu_color_contents_icon_leading}
              menuName={translate(item.title)}
              depthArrow={false}
              onClick={() => {
                item.showZendesk
                  ? clickZendeskOpen()
                  : window.open(getZendeskLink(item.link as ZendeskLinkType), '_blank');
              }}
            />
          );
        })}
      </Content>
    </>
  );
};

const Header = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 12px 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.token.s_color_border_default};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font: ${({ theme }) => theme.token.s_typography_title_S};
  color: ${({ theme }) => theme.token.s_color_contents_default};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`;
const Text = styled.span`
  font: ${({ theme }) => theme.token.s_typography_title_S};
  margin: 5px;
`;
const Content = styled.div`
  padding: 8px 16px 24px;
  width: inherit;
  height: calc(100% - 50px);
  overflow-y: auto;
`;
