import { ReactNode } from 'react';
import { BottomSheetProvider } from '@miri-unicorn/miricanvas-ds';
import { ConfirmModalProvider } from '@ui/common/dialog_provider/confirm_modal_provider/ConfirmModalProvider';
import { SnackbarProvider } from '@ui/common/dialog_provider/snackbar_provider/SnackbarProvider';
import { PopupProvider } from '@ui/common/popup_provider/PopupProvider';
import { ZendeskScripts } from '@ui/common/zendesk/ZendeskScripts';
import { HelpCenterPopoverWithAnchor } from '@ui/main_page/_common/_components/HelpCenterPopoverWithAnchor';

export const MainPageProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <ZendeskScripts />
      <HelpCenterPopoverWithAnchor />
      <ConfirmModalProvider>
        <SnackbarProvider>
          <PopupProvider>
            <BottomSheetProvider>{children}</BottomSheetProvider>
          </PopupProvider>
        </SnackbarProvider>
      </ConfirmModalProvider>
    </>
  );
};
