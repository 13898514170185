import { atom, useAtom } from 'jotai';

export const latestHistoryIdxAtom = atom<number | undefined>(undefined);

/**
 * 현재 디자인의 최신 히스토리 인덱스
 * - 정확한 값은 {@link useLatestHistoryIdx} 사용
 */
export const useLatestHistoryIdxStore = () => {
  const [latestHistoryIdx, setLatestHistoryIdx] = useAtom(latestHistoryIdxAtom);

  return {
    latestHistoryIdx,
    setLatestHistoryIdx,
  };
};
