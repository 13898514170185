import styled, { css } from 'styled-components';
import { Icon } from '@miri-unicorn/miricanvas-ds';

export const HelpCenterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button onClick={onClick}>
      <Icon width={'30px'} height={'30px'} iconName={'help_filled'} color={'white'} />
    </Button>
  );
};

const Button = styled.div`
  position: fixed;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(119deg, #a058ff -9%, #00ccbb 111%);
  border-radius: 50%;
  z-index: 2;

  ${({ theme }) =>
    theme.media.over1280Desktop(css`
      bottom: 32px;
      right: 32px;
    `)}

  ${({ theme }) =>
    theme.media.under1280Desktop(css`
      bottom: 24px;
      right: 24px;
    `)}

  ${({ theme }) =>
    theme.media.under500Mobile(css`
      bottom: 16px;
      right: 16px;
    `)}
  
  cursor: pointer;
  color: #ffffff;
  transition: margin-bottom 0.3s ease-out;
`;
