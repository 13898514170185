import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';
import { ChildGuideList } from '@ui/common/help_center/_components/ChildGuideList';
import { Footer } from '@ui/common/help_center/_components/Footer';
import { MainGuideList } from '@ui/common/help_center/_components/MainGuideList';
import { useZendeskWidget } from '@ui/common/zendesk/useZendeskWidget';
import { filterMailBoxButton, getGuideListByLanguage } from './_utils/guideUtils';

type HelpCenterPopupProps = {
  onClose: () => void;
};

export type CustomerGuideItem = {
  title: string;
  category?: string;
  headerItems?: CustomerGuideItem[];
  link?: string;
  showZendesk?: boolean;
  noQuestion?: boolean;
  questions?: CustomerGuideItem[];
};

export const HelpCenterPopup = ({ onClose }: HelpCenterPopupProps) => {
  const [isChildDepth, setIsChildDepth] = useState(false);
  const [childDepthTitle, setChildDepthTitle] = useState<string>('');
  const [childDepthGuideList, setChildDepthGuideList] = useState<CustomerGuideItem[] | []>([]);
  const { currentLanguage } = useCurrentLanguage();
  const { openZendeskWidget } = useZendeskWidget({});

  const allGuideList = useMemo(
    () => filterMailBoxButton(getGuideListByLanguage(currentLanguage)),
    [currentLanguage]
  );

  const toChildGuideList = (childGuidList?: CustomerGuideItem[]) => {
    setChildDepthGuideList(childGuidList ?? []);
    setIsChildDepth(true);
  };
  const backToMainGuideList = () => {
    setChildDepthGuideList([]);
    setIsChildDepth(false);
  };

  const clickZendeskOpen = () => {
    onClose();
    void openZendeskWidget();
  };

  return (
    <Container>
      {!isChildDepth ? (
        <MainGuideList
          onClose={onClose}
          allGuideList={allGuideList}
          clickZendeskOpen={clickZendeskOpen}
          setChildDepthTitle={setChildDepthTitle}
          toChildGuideList={toChildGuideList}
        />
      ) : (
        <ChildGuideList
          onClose={onClose}
          backToMainGuideList={backToMainGuideList}
          childDepthTitle={childDepthTitle}
          childDepthGuideList={childDepthGuideList}
          clickZendeskOpen={clickZendeskOpen}
        />
      )}
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.token.s_color_background_elevation01};
`;
