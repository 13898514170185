import { EditorInitUserDesignConfigRes } from '@miri-unicorn/miricanvas-editor/lib/types/miricanvas-shared-web/src/common/model/EditorInitType';
import { latestHistoryIdxAtom } from '@business/editor/_stores/useLatestHistoryIdxStore';
import { getRootStore } from '@configs/jotai/RootProvider';
import { useEditorV1PropsContext } from '@configs/miricanvas-editor/v1/EditorV1PropsContext';
import { useCallback } from 'react';

/**
 * editorProps 값에는 구독되고 있음.
 * initialLatestHistoryIdx 값에 구독하고 싶지 않을때 사용
 *
 * TODO - editorProps도 구독없이 값을 얻을 수 있다면, 훅이 아닌 순수함수로 변경
 */
export const useGetLatestHistoryIdxSnapshot = () => {
  const { editorProps } = useEditorV1PropsContext();
  const initialLatestHistoryIdx = (
    editorProps?.editorConfigs.editorConfig as EditorInitUserDesignConfigRes
  )?.latestDesignHistoryIdx;

  return useCallback(
    () => getRootStore().get(latestHistoryIdxAtom) ?? initialLatestHistoryIdx,
    [initialLatestHistoryIdx]
  );
};
