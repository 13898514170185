import { MAIN_PAGE_SEO } from '@configs/seo/_constants/MainPageSeo';
import { MultiLangSeoMetaDataType } from '@configs/seo/_types/SeoMetaDataType';

/**
 * 찜 페이지의 SEO 설정
 * 정해진 내용이 없기 때문에 기본 SEO 설정만 적용
 * TODO: 프프와 논의 후 SEO 변경, 타이틀 국제화 작업
 */
export const FAVORITE_PAGE_SEO = {
  ko: {
    ...MAIN_PAGE_SEO.ko,
    title: '디자인 플랫폼 미리캔버스',
  },
  en: {
    ...MAIN_PAGE_SEO.en,
    title: 'MiriCanvas | Free design tool with unique templates',
  },
  ja: {
    ...MAIN_PAGE_SEO.ja,
    title: '無料デザインプラットフォームMiriCanvas(ミリキャンバス)',
  },
  de: {
    ...MAIN_PAGE_SEO.de,
    title: 'MiriCanvas | Kostenloses Design-Tool mit einzigartigen Vorlagen',
  },
  es: {
    ...MAIN_PAGE_SEO.es,
    title: 'MiriCanvas | Herramienta de diseño gratis con plantillas únicas',
  },
  fr: {
    ...MAIN_PAGE_SEO.fr,
    title: 'MiriCanvas | Outil de conception gratuit avec des modèles uniques',
  },
  it: {
    ...MAIN_PAGE_SEO.it,
    title: 'MiriCanvas | strumento di progettazione gratuito con modelli unici',
  },
  nl: {
    ...MAIN_PAGE_SEO.nl,
    title: 'MiriCanvas | Gratis ontwertool met unieke templates',
  },
  pt: {
    ...MAIN_PAGE_SEO.pt,
    title: 'MiriCanvas | Ferramenta de design gratuita com modelos únicos',
  },
} satisfies MultiLangSeoMetaDataType;
