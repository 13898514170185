import { useCallback, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { HelpCenterButton } from '@ui/common/help_center/HelpCenterButton';
import { HelpCenterPopup } from '@ui/common/help_center/HelpCenterPopup';
import { useBoolean } from '@utils/hooks';

export const HelpCenterPopoverWithAnchor = () => {
  const { value: isVisible, setValue: setIsVisible } = useBoolean(false);
  const togglePopover = () => setIsVisible(!isVisible);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    },
    [setIsVisible]
  );

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isVisible]);

  return (
    <>
      <HelpCenterButton onClick={togglePopover} />
      {isVisible && (
        <PopoverContainer isVisible={isVisible} ref={popoverRef}>
          <HelpCenterPopup onClose={togglePopover} />
        </PopoverContainer>
      )}
    </>
  );
};

const PopoverContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 320px;
  height: 532px;
  max-height: 532px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.token.s_color_background_elevation01};
  box-shadow: ${({ theme }) => theme.token.s_shadow_popover};
  animation: ${({ isVisible }) => (isVisible ? fadeInUp : fadeOutDown)} 0.3s ease forwards;
  z-index: 2;
  @media (max-width: 768px) {
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 75dvh;
    max-height: 75dvh;
    border-radius: 16px 16px 0 0;
    animation: ${({ isVisible }) => (isVisible ? slideInUp : slideOutDown)} 0.3s ease forwards;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;
