export const KoreanCustomerGuideData = [
  {
    title: 'SHARED.ZENDESK_1',
    category: 'MAILBOX',
    question: [],
  },
  {
    title: 'SHARED.ZENDESK_5',
    category: 'USAGE',
    questions: [
      {
        title: 'SHARED.SHARED_339',
        link: 'USERS_GUIDE',
      },
      {
        title: 'SHARED.SHARED_340',
        link: 'SHORT_CUT',
      },
      {
        title: 'SHARED.SHARED_341',
        link: 'WORK_SPACE_GUIDE',
      },
      {
        title: 'SHARED.SHARED_342',
        link: 'HOW_TO_USE_PICK',
      },
      {
        title: 'SHARED.SHARED_343',
        link: 'STORAGE',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.ZENDESK_8',
    category: 'PAYMENT',
    questions: [
      {
        title: 'SHARED.ZENDESK_9',
        link: 'REFUND_POLICY',
      },
      {
        title: 'SHARED.ZENDESK_10',
        link: 'MANAGE_PAYMENT_METHODS',
      },
      {
        title: 'SHARED.ZENDESK_11',
        link: 'PAYMENT_FAILED_2',
      },
      {
        title: 'SHARED.ZENDESK_12',
        link: 'CHECK_PAYMENT_HISTORY',
      },
      {
        title: 'SHARED.ZENDESK_13',
        link: 'PRO_PLAN_ESTIMATE',
      },
      {
        title: 'SHARED.ZENDESK_14',
        link: 'PAYMENT_RECEIPT',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.SHARED_338',
    category: 'PRICING',
    questions: [
      {
        title: 'SHARED.ZENDESK_16',
        link: 'COMPARE_FREE_AND_PRO',
      },
      {
        title: 'SHARED.ZENDESK_17',
        link: 'ENTERPRICE_POLICY',
      },
      {
        title: 'SHARED.ZENDESK_18',
        link: 'PRO_PLAN_ESTIMATE_2',
      },
      {
        title: 'SHARED.ZENDESK_19',
        link: 'PRO_PLAN_USAGE_AND_CANCELLATION',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.ZENDESK_20',
    category: 'PRINT',
    questions: [
      {
        title: 'SHARED.ZENDESK_21',
        link: 'ORDER_PRINT_QUESTIONS',
      },
      {
        title: 'SHARED.ZENDESK_22',
        link: 'DELIVERY_STATUS',
      },
      {
        title: 'SHARED.ZENDESK_23',
        link: 'PRINT_PRODUCTION_REQUEST',
      },
      {
        title: 'SHARED.ZENDESK_24',
        link: 'ORDER_PRINT_RECEIPT',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.ZENDESK_25',
    category: 'USER_INFO',
    questions: [
      {
        title: 'SHARED.ZENDESK_26',
        link: 'FIND_PASSWORD',
      },
      {
        title: 'SHARED.ZENDESK_27',
        link: 'CHANGE_MEMBERSHIP_INFORMATION',
      },
      {
        title: 'SHARED.ZENDESK_28',
        link: 'NAVER_ACCOUNT_2',
      },
      {
        title: 'SHARED.ZENDESK_29',
        link: 'MEMBERSHIP_WITHDRAWAL',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.ZENDESK_30',
    category: 'COPYRIGHT',
    questions: [
      {
        title: 'SHARED.SHARED_344',
        link: 'COPYRIGHT_QNA',
      },
      {
        title: 'SHARED.ZENDESK_31',
        link: 'PLAN_LICENSE',
      },
      {
        title: 'SHARED.ZENDESK_32',
        link: 'FONT_AVAILABILITY',
      },
      {
        title: 'SHARED.ZENDESK_33',
        link: 'WATERMARK',
      },
      {
        title: 'SHARED.ZENDESK_34',
        link: 'COPYRIGHT_GUIDE_3',
      },
      {
        title: 'SHARED.ZENDESK_35',
        link: 'CAN_USE_PEOPLE_PHOTO',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.ZENDESK_36',
    category: 'ERROR',
    questions: [
      {
        title: 'SHARED.ZENDESK_37',
        link: 'DESIGN_DOCUMENT_NOT_FOUND',
      },
      {
        title: 'SHARED.ZENDESK_38',
        link: 'STORAGE_EXCEEDED',
      },
      {
        title: 'SHARED.ZENDESK_39',
        link: 'PAYMENT_FAILED_2',
      },
      {
        title: 'SHARED.ZENDESK_40',
        link: 'PAGE_IS_SLOW',
      },
      {
        title: 'SHARED.ZENDESK_7',
        link: '',
        showZendesk: true,
      },
    ],
  },
  {
    title: 'SHARED.ZENDESK_41',
    category: 'NEW_FEATURE',
    showZendesk: true,
  },
  {
    title: 'SHARED.ZENDESK_42',
    category: 'PARTNERSHIP',
    showZendesk: true,
  },
  {
    title: 'SHARED.ZENDESK_7',
    showZendesk: true,
  },
];
