import Bowser from 'bowser';

type ClientInfo = {
  osInfo: string;
  browserInfo: string;
  deviceInfo: string;
};

const getNavigatorUserAgent = () => {
  return typeof navigator !== 'undefined' ? navigator.userAgent : '';
};
export const getClientInfo = (): ClientInfo => {
  const userAgent = getNavigatorUserAgent();
  const parsedResult = Bowser.parse(userAgent);

  const osInfo = `${parsedResult.os.name} / ${parsedResult.os.version}`;
  const browserInfo = `${parsedResult.browser.name} / ${parsedResult.browser.version}`;
  const deviceInfo = parsedResult.platform.type
    ? `${parsedResult.platform.type} / ${parsedResult.platform.vendor || '*'} / ${
        parsedResult.platform.model || '*'
      }`
    : '';

  return { osInfo, browserInfo, deviceInfo };
};
