import { DEFAULT_SEO_LANGUAGE } from '@configs/seo/_constants/CommonSeoConst';
import { LanguageType } from '@constants/LanguageType';

const BASE_URL = 'https://www.miricanvas.com';

/**
 * Canonical URL 생성 함수
 * - 현재 접속한 페이지의 주소로 생성해야 한다.
 */
export const makeTemplateAlternateUrl = (lang: LanguageType) => {
  return {
    lang,
    href: lang === DEFAULT_SEO_LANGUAGE ? `${BASE_URL}/templates` : `${BASE_URL}/${lang}/templates`,
  };
};

export const makeMainpageAlternateUrl = (lang: LanguageType) => {
  return {
    lang,
    href: lang === DEFAULT_SEO_LANGUAGE ? `${BASE_URL}` : `${BASE_URL}/${lang}`,
  };
};
