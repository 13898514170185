import { decodeIdx } from '@business/design/_services/_pures/designUtil';
import { getDesignKeySnapshotValue } from '@business/editor/_stores/useDesignKeyStore';
import { useEditorV1PropsContext } from '@configs/miricanvas-editor/v1/EditorV1PropsContext';

export const useGetDesignIdxSnapshotValue = () => {
  const { editorProps } = useEditorV1PropsContext();
  const getDesignIdxSnapshotValue = () => {
    const initialDesignKey = editorProps?.designKey;
    const designKeySnapshotValue = getDesignKeySnapshotValue();
    const designKey = designKeySnapshotValue ?? initialDesignKey;

    return { designIdx: designKey ? decodeIdx(designKey) : undefined };
  };

  return { getDesignIdxSnapshotValue };
};
