import Script from 'next/script';

export const ZendeskScripts = () => (
  <>
    <Script
      id='ze-snippet'
      src='https://static.zdassets.com/ekr/snippet.js?key=b9aa39cd-f44a-4b1b-8bca-05ab878dc6fd'
      strategy='lazyOnload'
    />
  </>
);
