export type ZEFunctionCMD = 'close' | 'open' | 'setLocale' | 'logout' | 'updateSettings';
export type ZEFunction = (
  webWidget: 'webWidget' | 'webWidget:on',
  cmd: ZEFunctionCMD,
  option?: (() => void) | string | object
) => void;
export type ZEObject = {
  identify?: (param: { email?: string; name?: string }) => void;
};

export const TicketFormId = {
  DEFAULT: '1900000225908',
};

export const FieldId = {
  TITLE: '360015080111',
  CATEGORY: '360025318731',
  DESCRIPTION: 'description',
  PLAN: '6480341630105',
};

export type ZendeskFormOption = Partial<{
  title: string;
  category: string;
  firstMessage: string;
  lastMessage: string;
}>;
