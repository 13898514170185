import { atom, useAtom } from 'jotai';
import { getRootStore } from '@configs/jotai/RootProvider';

export const designKeyAtom = atom<string | undefined>(undefined);

/**
 * 에디터 저장 시, url - design/ 뒤에 해당하는 값
 * https://*.miricanvas.com/design/12900lf 에서 '12900lf'
 *
 * @see useDesignKey 훅 사용하기!
 * editorProps 의 designKey 값도 있고, 특정 designKey 의 url 로 이동 시, designKey 가 undefined 라서,
 * 통합하여 useDesignKey 훅에서 관리
 */
export const useDesignKeyStore = () => {
  const [designKey, setDesignKey] = useAtom(designKeyAtom);

  return {
    designKey,
    setDesignKey,
  };
};

export const getDesignKeySnapshotValue = () => getRootStore().get(designKeyAtom);
