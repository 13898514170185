import { useCallback, useEffect, useState } from 'react';
import { useGetDesignIdxSnapshotValue } from '@business/editor/_services/_hooks/useGetDesignIdxSnapshotValue';
import { useGetLatestHistoryIdxSnapshot } from '@business/editor/_services/_hooks/useGetLatestHistoryIdxSnapshot';
import { useCurrentUser } from '@business/user/_services/_hooks/useCurrentUser';
import { BuildEnv } from '@configs/environments/BuildEnv';
import { useTranslation } from '@configs/i18next';
import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';
import { getClientInfo } from '@ui/common/_utils/getClientInfo';
import { FieldId, TicketFormId, ZendeskFormOption } from '@utils/types/zendesk';

export const useZendeskWidget = ({
  formOption = undefined,
}: {
  formOption?: ZendeskFormOption;
}) => {
  const [zendeskFormOption, setZendeskFormOption] = useState<ZendeskFormOption | undefined>(
    formOption
  );

  const [webWidget, setWebWidget] = useState<HTMLElement | null>(null);
  const { user, currentPlan, selectedTeam, isLogin } = useCurrentUser();
  const { translate } = useTranslation();
  const { currentLanguage } = useCurrentLanguage();
  const { getDesignIdxSnapshotValue } = useGetDesignIdxSnapshotValue();
  const getDesignHistoryIdx = useGetLatestHistoryIdxSnapshot();
  const { designIdx } = getDesignIdxSnapshotValue();

  const fetchWidget = useCallback((): Promise<HTMLElement> => {
    return new Promise<HTMLElement>((resolve) => {
      const intervalId = window.setInterval(() => {
        const widget = document.querySelector<HTMLElement>('[data-product="web_widget"]');
        if (widget) {
          window.clearInterval(intervalId);
          resolve(widget);
        }
      }, 400);
    });
  }, []);

  const updateSettings = useCallback(
    (description: string): void => {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            ticketForms: [
              {
                id: TicketFormId.DEFAULT,
                title: false,
                fields: [
                  { id: FieldId.TITLE, prefill: { '*': zendeskFormOption?.title || '' } },
                  { id: FieldId.CATEGORY, prefill: { '*': zendeskFormOption?.category || '' } },
                  { id: FieldId.DESCRIPTION, prefill: { '*': description } },
                  { id: FieldId.PLAN, prefill: { '*': currentPlan.toLowerCase() || '' } },
                ],
              },
            ],
          },
        },
      });
    },
    [currentPlan, zendeskFormOption?.category, zendeskFormOption?.title]
  );

  const updateZendeskDescription = useCallback((): void => {
    const version = BuildEnv.NEXT_PUBLIC_APP_VERSION;
    const designId = getDesignHistoryIdx()?.toString()?.trim() ?? '0';
    const designIndex = designIdx?.toString()?.trim() ?? '0';
    const userAccountId = user?.accountId;
    const workspaceId = selectedTeam?.idx ?? '0';
    const plan = currentPlan?.toLowerCase() ?? 'free';
    const clientInfo = getClientInfo();

    const description = `${zendeskFormOption?.firstMessage ?? ''}\n
    ${translate('SHARED.HELP.INQUIRY.DESCRIPTION_1')}${translate(
      'SHARED.HELP.INQUIRY.DESCRIPTION_2',
      { device: clientInfo.deviceInfo }
    )}${translate('SHARED.HELP.INQUIRY.DESCRIPTION_3', { os: clientInfo.osInfo })}${translate(
      'SHARED.HELP.INQUIRY.DESCRIPTION_4',
      { browser: clientInfo.browserInfo }
    )}${translate('SHARED.HELP.INQUIRY.DESCRIPTION_5', { ver: version })}${translate(
      'SHARED.HELP.INQUIRY.DESCRIPTION_6',
      { id: designIndex, designId }
    )}${translate('SHARED.HELP.INQUIRY.DESCRIPTION_7', { userAccountId })}${translate(
      'SHARED.HELP.INQUIRY.DESCRIPTION_8',
      { workspaceId }
    )}${translate('SHARED.HELP.INQUIRY.DESCRIPTION_9', { plan })}${
      zendeskFormOption?.lastMessage ?? ''
    }\n${translate('SHARED.SHARED_317')} : ${location.origin + location.pathname}`;

    updateSettings(description.trim());
  }, [
    translate,
    designIdx,
    getDesignHistoryIdx,
    user?.accountId,
    selectedTeam?.idx,
    currentPlan,
    zendeskFormOption,
    updateSettings,
  ]);

  const initWidget = useCallback(async () => {
    if (webWidget) {
      return;
    }

    const widget = await fetchWidget();
    setWebWidget(widget);

    window.zE('webWidget:on', 'open', () => updateZendeskDescription());
    window.zE('webWidget', 'setLocale', currentLanguage);
  }, [currentLanguage, fetchWidget, updateZendeskDescription, webWidget]);

  const openZendeskWidget = useCallback(async () => {
    await initWidget();

    if (typeof window.zE?.identify === 'function') {
      window.zE.identify({
        email: isLogin ? user?.email : '',
        name: isLogin ? user?.name : '',
      });
    }

    window.zE('webWidget', 'close');
    window.zE('webWidget', 'open');
  }, [initWidget, isLogin, user?.email, user?.name]);

  const closeZendeskWidget = useCallback(() => {
    setZendeskFormOption(undefined);
  }, []);

  useEffect(() => {
    return () => {
      closeZendeskWidget();
    };
  }, [closeZendeskWidget]);

  return {
    zendeskFormOption,
    openZendeskWidget,
    closeZendeskWidget,
  };
};
