import { LanguageType } from '@constants/LanguageType';
import { globalCustomerGuideData } from '@ui/common/help_center/_constants/GlobalCustomerGuideData';
import { KoreanCustomerGuideData } from '@ui/common/help_center/_constants/KoreanCustomerGuideData';

type CustomerGuideItem = {
  title: string;
  category?: string;
  headerItems?: CustomerGuideItem[];
  link?: string;
  showZendesk?: boolean;
  noQuestion?: boolean;
  questions?: CustomerGuideItem[];
};

export const getGuideListByLanguage = (lang: LanguageType): CustomerGuideItem[] => {
  return lang === 'ko' ? KoreanCustomerGuideData : globalCustomerGuideData;
};

export const filterMailBoxButton = (items: CustomerGuideItem[]) => {
  return items.filter((item) => item?.category !== 'MAILBOX');
};
