import { DEFAULT_SEO_LANGUAGE } from '@configs/seo/_constants/CommonSeoConst';
import { LanguageType } from '@constants/LanguageType';

const BASE_URL = 'https://www.miricanvas.com';

/**
 * canoical url 생성 함수
 * - 현재 접속한 페이지의 주소로 생성해야 한다.
 */
export const makeTemplateCanonicalUrl = (lang: LanguageType, detailPath?: string) => {
  const templateMainPath = getTemplateMainPath(lang);
  /**
   * 템플릿 메인페이지 이후 path가 붙었을 때 append
   * ex ) https://www.miricanvas.com/templates/[path]
   */
  return appendPath(templateMainPath, detailPath);
};

const getTemplateMainPath = (lang: LanguageType): string => {
  return `${BASE_URL}/${lang}/templates`;
};

const appendPath = (templateMainPath: string, detailPath?: string): string => {
  if (detailPath) {
    return `${templateMainPath}/${detailPath}`;
  } else {
    return templateMainPath;
  }
};

export const makeMainpageCanonicalUrl = (lang: LanguageType) => {
  return lang === DEFAULT_SEO_LANGUAGE ? `${BASE_URL}` : `${BASE_URL}/${lang}`;
};
