const TEMPLATE_TYPE_ID_REPLACEMENT_STRING = '[templateTypeId]';
const KEYWORD_REPLACEMENT_STRING = '[keyword]';

/**
 * 대체 문자열 생성
 * @param templateTypeId 템플릿 타입
 * @param keyword 검색어
 * @returns 대체 문자열
 */
export const getReplacementStrings = (
  templateTypeId: string = TEMPLATE_TYPE_ID_REPLACEMENT_STRING,
  keyword: string = KEYWORD_REPLACEMENT_STRING
) => `${keyword} ${templateTypeId}`;

/**
 * 템플릿 상세 title, desc [keyword][templateTypeId] 이름 보정
 * `무료 [keyword][templateTypeId] 템플릿 - 미리캔버스` 에서 [keyword][templateTypeId] 부분을 보정
 * ex) `무료 [keyword][templateTypeId] 템플릿 - 미리캔버스` -> `무료 여름 카드뉴스 템플릿 - 미리캔버스`
 * @param originalSeoStr 보정 전 문자열
 * @param templateTypeIdStr 템플릿 타입
 * @param templateKeywordStr 검색어
 * @returns 보정된 seo 문자열
 * */
export const replaceTemplateSeoString = (
  originalSeoStr: string,
  templateTypeIdStr?: string,
  templateKeywordStr?: string
) => {
  return originalSeoStr
    .replace(TEMPLATE_TYPE_ID_REPLACEMENT_STRING, templateTypeIdStr ?? '')
    .replace(KEYWORD_REPLACEMENT_STRING, templateKeywordStr ?? '');
};
