import React from 'react';
import styled from 'styled-components';
import { Button } from '@miri-unicorn/miricanvas-ds/v2';
import { useTranslation } from '@configs/i18next';
import { getZendeskLink } from '@constants/zendesk_link';

export const Footer = () => {
  const { translate } = useTranslation();
  return (
    <FooterContainer>
      <Button
        size={'M'}
        type={'fill'}
        color={'neutralDeep'}
        shape={'square'}
        widthType={'fill'}
        onClick={() => window.open(getZendeskLink('HELP_CENTER_MAIN'), '_blank')}>
        {translate('SHARED.SHARED_337')}
      </Button>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.token.s_color_border_default};
`;
