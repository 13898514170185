import Head from 'next/head';
import { AlternateType } from '@configs/seo/_types/AlternateType';
import { SeoMetaDataType } from '@configs/seo/_types/SeoMetaDataType';

export const SeoMetaTags = ({ seoConfig }: { seoConfig: SeoMetaDataType }) => (
  <Head>
    {/** 페이지 제목 태그 */}
    <title>{seoConfig.title}</title>
    {/** 페이지 설명 메타 태그 */}
    <meta name='description' content={seoConfig.desc} />
    {/** 페이지 문자 인코딩 설정 태그 */}
    <meta charSet='UTF-8' />
    {/** 뷰포트 설정 태그 */}
    <meta
      name='viewport'
      content={`width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=${
        seoConfig.restrictScale ? 1 : 10
      }, user-scalable=${
        seoConfig.restrictScale ? 'no' : 'yes'
      }, viewport-fit=cover, interactive-widget=resizes-content`}
    />

    {/** 페이지 대표 URL 설정 태그 */}
    {seoConfig.canonical && <link rel='canonical' href={seoConfig.canonical} />}

    {/** no-index 처리 (크롤링 방지) */}
    {seoConfig.noIndex && <meta name='robots' content='noindex'></meta>}

    {/** 국제화 관련 태그
     * alternate undefined일 때 태그 X
     * alternate 빈 문자열('')일 때 메인 페이지 태그 (ex. https://www.miricanvas.com/en )`
     * alternate 문자열 있을 때 : 해당 페이지 태그 (ex. seoConfig.alternate = 'templates' https://www.miricanvas.com/en/templates)
     */}
    {/** 국제화 관련 태그 */}
    {seoConfig.alternate &&
      seoConfig.alternate.map(({ lang, href }: AlternateType) => (
        <link key={lang} rel='alternate' hrefLang={lang} href={href} />
      ))}

    {/** og 관련 태그*/}
    <meta property='og:title' content={seoConfig.title} />
    <meta property='og:description' content={seoConfig.ogDesc} />
    <meta property='og:type' content='website' />
    <meta property='og:site_name' content={seoConfig.ogSiteName} />
    <meta property='og:image' content={seoConfig.ogImageUrl} />
    <meta property='og:image:width' content={seoConfig.ogImageWidth} />
    <meta property='og:image:height' content={seoConfig.ogImageHeight} />

    {/**  트위터 관련 태그 */}
    <meta name='twitter:image' content={seoConfig.ogImageUrl} />
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:site' content='@miricanvas' />
    <meta name='twitter:title' content={seoConfig.title} />
    <meta name='twitter:description' content={seoConfig.desc} />
    <meta property='og:image:secure_url' content={seoConfig.ogImageUrl} />
    <meta name='twitter:creator' content='@miricanvas' />

    {/**  페이스북 관련 태그 */}
    <meta property='fb:app_id' content='561547997625169' />

    {/** site-verification 관련 태그 */}
    <meta name='naver-site-verification' content='a51660ca10be88ce5e2ea8d2682e3a27439c1829' />
    <meta name='google-site-verification' content='nwbI3Y2xmf9ighkhObFEMgbQFiykpcVtBLeyanUNzEk' />
    <meta name='facebook-domain-verification' content='o779dvl35rxbwipnag9ggd5w723h9q' />
    <meta name='p:domain_verify' content='6f9874cfe3c5e88c6fea55b2bc27f8c5' />
  </Head>
);
