import React from 'react';
import styled, { css } from 'styled-components';
import { IconButton } from '@miri-unicorn/miricanvas-ds';
import { useTranslation } from '@configs/i18next';

export const SubHeaderGuide = () => {
  const { translate } = useTranslation();
  return (
    <FAQContainer>
      <FAQHeader>
        <Title onClick={() => window.open('https://www.miricanvas.com/s/2251', '_blank')}>
          {translate('SHARED.HELP.FAQ')}
        </Title>
        <IconButton
          size={'XXS'}
          type={'ghost'}
          color={'neutralLight'}
          shape={'square'}
          iconName={'chevron_right_2px'}
          onClick={() => window.open('https://www.miricanvas.com/s/2251', '_blank')}
        />
      </FAQHeader>
      <FAQList>
        <FAQItem>
          <QuestionBox onClick={() => window.open('https://www.miricanvas.com/s/2252', '_blank')}>
            <QuestionText>{translate('SHARED.SHARED_335')}</QuestionText>
          </QuestionBox>
        </FAQItem>
        <FAQItem>
          <QuestionBox onClick={() => window.open('https://www.miricanvas.com/s/2253', '_blank')}>
            <QuestionText>{translate('SHARED.SHARED_336')}</QuestionText>
          </QuestionBox>
        </FAQItem>
      </FAQList>
    </FAQContainer>
  );
};

const FAQContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.token.c_banner_color_border_normal_medium};
  background: ${({ theme }) => theme.token.c_banner_color_background_normal_notStrong};
  padding: 12px 16px;
`;

const FAQHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.token.c_textButton_color_contents_neutralDeep_default};
  font: ${({ theme }) => theme.token.c_textButton_typography_M};
  cursor: pointer;
`;
const FAQItem = styled.li`
  margin-bottom: 5px;
  list-style-type: disc;
`;

const FAQList = styled.ul`
  margin: 0;
  padding-left: 16px;
`;

const QuestionBox = styled.p`
  display: flex;
  cursor: pointer;
  font: 500 0.875rem/146% Pretendard Variable, Figtree, IBM Plex Sans JP, Pretendard JP Variable;

  ${({ theme }) => theme.mixin.setTransition(`all 100ms ease-in-out`)}
  ${({ theme }) => {
    return theme.mixin.hoverDesktopOnly(css`
      &:hover {
        color: #99a1ad;
        text-decoration-line: underline;
      }
    `);
  }}
`;

const QuestionText = styled.span`
  word-wrap: break-word;
  text-align: left;
  display: inline-block;
  align-items: flex-start;
  justify-content: center;
`;
