/**
 * SEO를 위한 기본 언어 설정
 * @constant {LanguageType}
 * @default 'ko'
 * @description SEO 최적화를 위해 사용되는 기본 언어 코드
 * - 기본적으로 페이지가 어떤 언어로 설정되어야 하는지 결정하는 상수
 * - 여러 언어 버전 중 기본으로 선택될 언어 지정
 * - makeCanonicalUrl.ts, makeAlternateUrl.ts에서 사용
 */
export const DEFAULT_SEO_LANGUAGE = 'ko';
