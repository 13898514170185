import { RESOURCE_DOMAIN } from '@constants/ServerPath';
import { MultiLangSeoMetaDataType } from '@configs/seo/_types/SeoMetaDataType';
import { getReplacementStrings } from '@configs/seo/utils/replaceTemplateSeoString';

const DEFAULT_KOREAN_OG_SITE_NAME = '미리캔버스';
const DEFAULT_GLOBAL_OG_SITE_NAME = 'MiriCanvas';
const DEFAULT_OG_IMAGE_WIDTH = '1200';
const DEFAULT_OG_IMAGE_HEIGHT = '675';
const DEFAULT_OG_TYPE = 'website';
const DEFAULT_RESTRICT_SCALE = false;
/**
 * 템플릿 SEO 공통(기본) 상수
 */
export const BASE_TEMPLATE_SEO = {
  ko: {
    title: '더 나은 디자인을 위한 무료 템플릿 | 미리캔버스',
    desc: '미리캐버스의 유니크한 무료 템플릿을 사용하여 쉽고 간편하게 전문가 수준의 디자인을 만들어보세요.',
    keywords: '무료 디자인 템플릿, 미리캔버스, miricanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_ko.png`,
    ogSiteName: DEFAULT_KOREAN_OG_SITE_NAME,
    ogDesc:
      '미리캐버스의 유니크한 무료 템플릿을 사용하여 쉽고 간편하게 전문가 수준의 디자인을 만들어보세요.',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  en: {
    title: 'Unique free templates for better design | Miricanvas',
    desc: 'Find a unique, distinctive design template perfect for your needs at MiriCanvas.',
    keywords:
      'unique design templates, free design templates, unique design templates, free design tool, copyright free, MiriCanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc: 'Find a unique, distinctive design template perfect for your needs at MiriCanvas.',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  ja: {
    title: 'より良いデザインのための無料テンプレート | MiriCanvas(ミリキャンバス)',
    desc: '無料プラン、個人/団体向けプランなど、様々なニーズに合わせた料金プランをご用意しております。デザイン作業に必要なプレミアムコンテンツ、AI、デザインツールなど、用途に合わせたオーダーメイド型料金プランをご利用ください。',
    keywords:
      '無料テンプレート, オンラインデザインツール, コラボレーションツール, 事前キャンバス料金プラン, MiriCanvas, 商業用無料画像, ミリキャンバス',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc:
      '無料プラン、個人/団体向けプランなど、様々なニーズに合わせた料金プランをご用意しております。デザイン作業に必要なプレミアムコンテンツ、AI、デザインツールなど、用途に合わせたオーダーメイド型料金プランをご利用ください。',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  de: {
    title: 'MiriCanvas | Einzigartige Vorlagen für bessere Designs',
    desc: 'Finde bei MiriCanvas eine einzigartige, unverwechselbare Designvorlage, die genau zu deinem Projekt passt.',
    keywords:
      'einzigartige Designvorlagen, kostenlose Designvorlagen, einzigartige Designvorlagen, kostenloses Designtool, Copyright frei, MiriCanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc:
      'Finde bei MiriCanvas eine einzigartige, unverwechselbare Designvorlage, die genau zu deinem Projekt passt.',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  es: {
    title: 'MiriCanvas | Plantillas únicas para mejores diseños',
    desc: 'Encuentra en MiriCanvas una plantilla de diseño única, distinta, y adecuada para lo que necesites.',
    keywords:
      'plantillas de diseño únicas, plantillas de diseño gratis, plantillas de diseño únicas, herramienta de diseño gratis, sin derechos de autor, MiriCanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc:
      'Encuentra en MiriCanvas una plantilla de diseño única, distinta, y adecuada para lo que necesites.',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  fr: {
    title: 'MiriCanvas | Des modèles uniques pour de meilleurs designs',
    desc: 'Trouvez un design unique et distinctif adapté à votre utilisation sur MiriCanvas.',
    keywords: `Modèles de conception uniques, designs et outils de conception libre de droits d'auteur, MiriCanvas`,
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc: 'Trouvez un design unique et distinctif adapté à votre utilisation sur MiriCanvas.',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  it: {
    title: 'MiriCanvas | Modelli unici per un design migliore',
    desc: 'Trova su MiriCanvas il modello di design unico che fa per te',
    keywords: 'modelli e strumenti di design gratuiti e unici e copyright free, MiriCanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc: 'Trova su MiriCanvas il modello di design unico che fa per te',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  nl: {
    title: 'MiriCanvas | Unieke templates voor betere ontwerpen',
    desc: 'Vindt een uniek, kenmerkend ontwerp geschikt voor jouw gebruik bij MiriCanvas',
    keywords:
      'Unieke ontwerp templates, Gratis ontwerp templates, unieke ontwerp templates, gratis ontwerptool, auteursrechtvrij, MiriCanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc: 'Vindt een uniek, kenmerkend ontwerp geschikt voor jouw gebruik bij MiriCanvas',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  pt: {
    title: 'MiriCanvas | Modelos exclusivos para melhores designs',
    desc: 'Encontre um modelo de design exclusivo e distinto adequado para a sua utilização no MiriCanvas.',
    keywords:
      'modelos de design exclusivos, modelos de design gratuitos, modelos de design exclusivos, ferramenta de design gratuita, sem direitos autorais, MiriCanvas',
    ogImageUrl: `${RESOURCE_DOMAIN}2_0/image/og/OG_templates_all.png`,
    ogSiteName: DEFAULT_GLOBAL_OG_SITE_NAME,
    ogDesc:
      'Encontre um modelo de design exclusivo e distinto adequado para a sua utilização no MiriCanvas.',
    ogType: DEFAULT_OG_TYPE,
    ogImageWidth: DEFAULT_OG_IMAGE_WIDTH,
    ogImageHeight: DEFAULT_OG_IMAGE_HEIGHT,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
} satisfies MultiLangSeoMetaDataType;

/**
 * 템플릿 타입 SEO 상수
 */
export const TEMPLATE_TYPE_SEO = {
  ko: {
    ...BASE_TEMPLATE_SEO.ko,
    title: '전문적인 디자인을 위한 다양한 템플릿 타입 | 미리캔버스',
    desc: '미리캔버스에서 제공하는 다양한 템플릿 타입을 통해 보다 전문적인 디자인을 만들어보세요.',
  },
  en: {
    ...BASE_TEMPLATE_SEO.en,
    title: 'Template Types for Professional Design  | MiriCanvas',
    desc: 'Find a unique, distinctive design template type perfect for your needs at MiriCanvas.',
  },
  ja: {
    ...BASE_TEMPLATE_SEO.ja,
    title: 'プロフェッショナルなデザインのための様々なタイプ | MiriCanvas(ミリキャンバス)',
    desc: 'ミリキャンバスの様々なテンプレートタイプを使って、より専門的なデザインを作成しましょう。',
  },
  de: {
    ...BASE_TEMPLATE_SEO.de,
  },
  es: {
    ...BASE_TEMPLATE_SEO.es,
  },
  fr: {
    ...BASE_TEMPLATE_SEO.fr,
  },
  it: {
    ...BASE_TEMPLATE_SEO.it,
  },
  nl: {
    ...BASE_TEMPLATE_SEO.nl,
  },
  pt: {
    ...BASE_TEMPLATE_SEO.pt,
  },
} satisfies MultiLangSeoMetaDataType;

/**
 * 템플릿 타입 SEO 상수
 */
export const TEMPLATE_DETAIL_SEO = {
  ko: {
    ...BASE_TEMPLATE_SEO.ko,
    title: ' - 미리캔버스',
    ogImageWidth: '400',
  },
  en: {
    ...BASE_TEMPLATE_SEO.en,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
  ja: {
    ...BASE_TEMPLATE_SEO.ja,
    title: ' - MiriCanvas(ミリキャンバス)',
    ogImageWidth: '400',
  },
  de: {
    ...BASE_TEMPLATE_SEO.de,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
  es: {
    ...BASE_TEMPLATE_SEO.es,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
  fr: {
    ...BASE_TEMPLATE_SEO.fr,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
  it: {
    ...BASE_TEMPLATE_SEO.it,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
  nl: {
    ...BASE_TEMPLATE_SEO.nl,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
  pt: {
    ...BASE_TEMPLATE_SEO.pt,
    title: ' - MiriCanvas',
    ogImageWidth: '400',
  },
} satisfies MultiLangSeoMetaDataType;

/**
 * 템플릿 검색 SEO 상수
 */
export const TEMPLATE_SEARCH_SEO = {
  ko: {
    ...BASE_TEMPLATE_SEO.ko,
    title: `전문적인 디자인을 위한 무료 ${getReplacementStrings()} 템플릿 | 미리캔버스`,
    desc: `미리캔버스에서 제공하는 ${getReplacementStrings()} 템플릿을 통해 보다 전문적인 디자인을 만들어보세요.`,
  },
  en: {
    ...BASE_TEMPLATE_SEO.en,
    title: `Free ${getReplacementStrings()} Templates for Professional Design | MiriCanvas`,
    desc: `Find a unique, distinctive ${getReplacementStrings()} template perfect for your needs at MiriCanvas.`,
  },
  ja: {
    ...BASE_TEMPLATE_SEO.ja,
    title: `プロフェッショナルなデザインのための無料 ${getReplacementStrings()} テンプレート | MiriCanvas(ミリキャンバス)`,
    desc: `ミリキャンバスの${getReplacementStrings()} テンプレートを使って、より専門的なデザインを作成しましょう。`,
  },
  de: {
    ...BASE_TEMPLATE_SEO.de,
    title: 'Vorlagen | MiriCanvas',
  },
  es: {
    ...BASE_TEMPLATE_SEO.es,
    title: 'Plantillas | MiriCanvas',
  },
  fr: {
    ...BASE_TEMPLATE_SEO.fr,
    title: 'Modèles | MiriCanvas',
  },
  it: {
    ...BASE_TEMPLATE_SEO.it,
    title: 'Modelli | MiriCanvas',
  },
  nl: {
    ...BASE_TEMPLATE_SEO.nl,
    title: 'Templates | MiriCanvas',
  },
  pt: {
    ...BASE_TEMPLATE_SEO.pt,
    title: 'Modelos | MiriCanvas',
  },
} satisfies MultiLangSeoMetaDataType;

/**
 * 템플릿 타입 검색 결과 페이지용 SEO 데이터
 * 템플릿 타입 검색 결과 페이지 URL
 * - ex: www.miricanvas.com/templates/search?templateTypeId=presentation&purpose=ALL
 */
export const TEMPLATE_SEARCH_TEMPLATE_TYPE_ID_SEO = {
  ko: {
    ...BASE_TEMPLATE_SEO.ko,
    title: `전문적인 디자인을 위한 무료 ${getReplacementStrings()} 템플릿 | 미리캔버스`,
    desc: `미리캔버스에서 제공하는 ${getReplacementStrings()} 템플릿을 통해 보다 전문적인 디자인을 만들어보세요.`,
  },
  en: {
    ...BASE_TEMPLATE_SEO.en,
    title: `Free ${getReplacementStrings()} Templates for Professional Design | MiriCanvas`,
    desc: `Find a unique, distinctive ${getReplacementStrings()} template perfect for your needs at MiriCanvas.`,
  },
  ja: {
    ...BASE_TEMPLATE_SEO.ja,
    title: `プロフェッショナルなデザインのための無料 ${getReplacementStrings()} テンプレート | MiriCanvas(ミリキャンバス)`,
    desc: `ミリキャンバスの${getReplacementStrings()} テンプレートを使って、より専門的なデザインを作成しましょう。`,
  },
  de: {
    ...BASE_TEMPLATE_SEO.de,
    title: `Kostenlose ${getReplacementStrings()} Vorlagen | MiriCanvas`,
  },
  es: {
    ...BASE_TEMPLATE_SEO.es,
    title: `Plantillas de ${getReplacementStrings()} Gratuitas | MiriCanvas`,
  },
  fr: {
    ...BASE_TEMPLATE_SEO.fr,
    title: `Modèles de ${getReplacementStrings()} Gratuits | MiriCanvas`,
  },
  it: {
    ...BASE_TEMPLATE_SEO.it,
    title: `Modelli di ${getReplacementStrings()} Gratuiti | MiriCanvas`,
  },
  nl: {
    ...BASE_TEMPLATE_SEO.nl,
    title: `Gratis ${getReplacementStrings()} Templates | MiriCanvas`,
  },
  pt: {
    ...BASE_TEMPLATE_SEO.pt,
    title: `Modelos de ${getReplacementStrings()} gratuitos | MiriCanvas`,
  },
} satisfies MultiLangSeoMetaDataType;
